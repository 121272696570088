import { combineReducers } from "redux";
import counts from "./countsReducer";
import device from "./deviceReducer";
import publicReducer from "./publicReducer";
import { mdetails, member } from "./memberReducer";

const rootReducer = combineReducers({
  counts,
  device,
  member,
  mdetails,
  ...publicReducer,
});

export default rootReducer;
