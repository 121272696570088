import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function device(state = initialState.device, action) {
  switch (action.type) {
    case types.UPDATE_DEVICE_DETAILS:
      delete action.type;
      return Object.assign(state, action);
    default:
      return state;
  }
}

export default device;
