import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function podcasts(state = initialState.podcasts, action) {
  switch (action.type) {
    case types.GET_PODCASTS_SUCCESS:
      return {
        all: action.podcasts || [],
        filtered: action.Podcasts || []
      };
    default:
      return state;
  }
}

export default {
  podcasts
};
