export default {
  counts: {
    overall: {
      graduates: 0,
      income: 0,
      members: 0,
      purchased: 0,
      value: 0
    },
    mastermind: {}
  },
  device: {
    browser: null,
    mobile: window.innerWidth < 769 ? true : false,
    width: window.innerWidth
  },
  lastEvaluatedKey: null,
  member: {},
  mdetails: {
    members: [],
    lastEvaluatedKey: null
  },
  podcasts: {
    all: [],
    filtered: []
  }
};
