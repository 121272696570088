import React from "react";

import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import axios from "axios";
import { render } from "react-dom";

//Router History
import history from "./history";

//App Entry Point
import App from "./App";

//Redux Config
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/configureStore";

//App Styling
import "./dist/css/app.css";
import "./dist/css/buttons.css";
import "./dist/css/cards.css";
import "./dist/css/color.css";
import "./dist/css/format.css";
import "./dist/css/index.css";

//Redux Startup actions
import startupActions from "./redux/actions/startupActions";

window.ga("set", "appName", process.env.REACT_APP_GOOGLE_APPNAME);

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common["X-Api-Key"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Startup Actions / Default Data
const store = new configureStore();
store.dispatch(startupActions());

render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById("app")
);

serviceWorker.register();
