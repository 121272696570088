//import * as types from "./actionTypes";
import getCountsAction from "./countsActions";
import { getMembersAction } from "./memberActions";
import updateDeviceDetails from "./deviceActions";

export default function startupActions() {
  return function (dispatch) {
    const mobile = window.innerWidth < 769 ? true : false;
    dispatch(
      updateDeviceDetails({
        mobile,
      })
    );
    dispatch(getCountsAction({ page: "home" }));
    dispatch(
      getMembersAction({ count: 12, mobile, select: "photo,contact_name" })
    );
  };
}
