import * as types from "../actions/actionTypes";
import initialState from "./initialState";

function member(state = initialState.member, action) {
  switch (action.type) {
    case types.UPDATE_MEMBER_SUCCESS:
      return Object.assign(state, ...action.member);
    default:
      return state;
  }
}

function mdetails(state = initialState.mdetails, action) {
  switch (action.type) {
    case types.GET_MEMBERS_SUCCESS:
      return action.mdetails;
    default:
      return state;
  }
}

export { member, mdetails };
