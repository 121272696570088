import React, { Suspense, lazy } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";

//Redux
import { connect } from "react-redux";

//Custom Methods
import Navbar from "./components/shared/navbar/Navbar";
import history from "./history";

const Home = lazy(() => import("./components/home/Home"));
const Accelerator = lazy(() => import("./components/accelerator/Accelerator"));
const AccelOverview = lazy(() => import("./components/accelerator/Overview"));
const Contactus = lazy(() => import("./components/contact/Contact"));
const FAQs = lazy(() => import("./components/faq/FAQ"));
const Members = lazy(() => import("./components/members/OurMembers"));
const Member = lazy(() => import("./components/member/Member"));
const Privacy = lazy(() => import("./components/legal/Privacy"));
const Terms = lazy(() => import("./components/legal/Terms"));
const Testimonials = lazy(() =>
  import("./components/testimonials/Testimonials")
);
const Turnkey = lazy(() => import("./components/turnkey/Turnkey"));
const WFS = lazy(() => import("./components/wfs/views/WFS"));

function App({ mobile }) {
  return (
    <React.Fragment>
      <Navbar mobile={mobile} />
      <Suspense fallback={<Navbar />}>
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/accelerator">
              <AccelOverview />
            </Route>
            <Route path="/accelerator/:slug" component={Accelerator} />
            <Route path="/contact">
              <Contactus />
            </Route>
            <Route path="/faqs" component={FAQs} />
            <Route path="/members/:member" component={Member} />
            <Route path="/members" component={Members} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/testimonials" component={Testimonials} />
            <Route path="/turnkey" component={Turnkey} />
            <Route path="/wfs" component={WFS} />
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </Suspense>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    mobile: state.device.mobile || false,
  };
}

export default connect(mapStateToProps)(App);
