/* Device Details */
export const UPDATE_DEVICE_DETAILS = "UPDATE_DEVICE_DETAILS";

/* Member Details */
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";

/* Counts (Members, Properties, Sales, etc) */
export const GET_STAT_COUNTS_SUCCESS = "GET_STAT_COUNTS_SUCCESS";

/* PUBLIC App Actions */
export const GET_PODCASTS_SUCCESS = "GET_PODCASTS_SUCCESS";
