import * as types from "./actionTypes";
import getCountsApi from "../../api/getCountsApi";

export default function getCountsAction(props) {
  return function(dispatch) {
    getCountsApi(props).then(counts => {
      dispatch({
        type: types.GET_STAT_COUNTS_SUCCESS,
        counts
      });
    });
  };
}
