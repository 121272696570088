import * as types from "./actionTypes";
import getMembersApi from "../../api/contact/getMembersApi";

export function updateMember(props) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_MEMBER_SUCCESS,
      member: props
    });
  };
}

export function getMembersAction(props) {
  return function(dispatch) {
    getMembersApi(props)
      .then(mdetails => {
        dispatch({
          type: types.GET_MEMBERS_SUCCESS,
          mdetails
        });
      })
      .catch(console.log);
  };
}
