import * as types from "./actionTypes";

export default function updateDeviceDetails(props) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_DEVICE_DETAILS,
      ...props
    });
  };
}
