const blueprint = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse1.png`;
const strategy = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse2.png`;
const research = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse3.png`;
const cashflow = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse4.png`;
const legal = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse5.png`;
const wealth = `${process.env.REACT_APP_ASSETS_ENDPOINT}?lvl1=img&lvl2=accelerator&lvl3=accelcourse6.png`;

export default {
  accelerator: {
    order: 0,
    overview: {
      alt: "Accelerator Overview | White Feather Investments",
      description: "Learn to create your personal path to Financial Freedom",
      header: "Overview",
      icon: null,
      link: "/accelerator",
      order: 0,
    },
    header: "The Accelerator",
    blueprint: {
      alt: "Financial Blueprint | White Feather Investments",
      description: "Learn to create your personal path to Financial Freedom",
      header: "Financial Blueprint",
      icon: blueprint,
      link: "/accelerator/blueprint",
      order: 1,
    },
    strategy: {
      alt: "Investment Strategies | White Feather Investments",
      description: "Discover incredibly powerful Acquisition Strategies",
      header: "Investment Strategy",
      icon: strategy,
      link: "/accelerator/strategies",
      order: 2,
    },
    research: {
      alt: "Research | White Feather Investments",
      description: "Macro and Micro Market Analysis",
      header: "Research",
      icon: research,
      link: "/accelerator/research",
      order: 3,
    },
    cashflow: {
      alt: "Cashflow | White Feather Investments",
      description: "Become an expert at running the numbers",
      header: "Cashflow",
      icon: cashflow,
      link: "/accelerator/cashflow",
      order: 4,
    },
    taxlegal: {
      alt: "Tax and Legal Strategy | White Feather Investments",
      description:
        "Learn Cutting Edge Tax and Legal Strategies that will save you THOUSANDS of dollars this year!",
      header: "Tax and Legal",
      icon: legal,
      link: "/accelerator/taxlegal",
      order: 5,
    },
    wealth: {
      alt: "Building Generational Wealth | White Feather Investments",
      description: "Advanced Strategies for Building Generational Wealth",
      header: "Generational Wealth",
      icon: wealth,
      link: "/accelerator/wealth",
      order: 6,
    },
  },
  investments: {
    order: 1,
    header: "Investments",
    memphis: {
      order: 0,
      geo: {
        lat: 35.1495,
        lng: -90.049,
      },
      header: "Memphis",
      id: "wfi-marker-0",
      link: "/turnkey#memphis",
      state: "TN",
    },
    birmingham: {
      order: 1,
      geo: {
        lat: 32.3770835,
        lng: -86.2844187,
      },
      header: "Birmingham",
      id: "wfi-marker-0",
      link: "/turnkey#birmingham",
      state: "",
    },
    littlerock: {
      order: 2,
      geo: {
        lat: 34.7465,
        lng: -92.2896,
      },
      header: "Little Rock",
      id: "wfi-marker-1",
      link: "/turnkey#littlerock",
      state: "",
    },
    stlouis: {
      order: 3,
      geo: {
        lat: 38.627,
        lng: -90.1994,
      },
      header: "St Louis",
      id: "wfi-marker-2",
      link: "/turnkey#stlouis",
      state: "",
    },
    chicago: {
      order: 4,
      geo: {
        lat: 41.8781,
        lng: -87.6298,
      },
      header: "Chicago",
      id: "wfi-marker-3",
      link: "/turnkey#chicago",
      state: "",
    },

    milan: {
      order: 5,
      geo: {
        lat: 35.9198,
        lng: -88.7589,
      },
      header: "Milan",
      id: "wfi-marker-4",
      link: "/turnkey#milan",
      state: "",
    },
    columbus: {
      order: 6,
      geo: {
        lat: 32.461,
        lng: -84.9877,
      },
      header: "Columbus",
      id: "wfi-marker-5",
      link: "/turnkey#columbus",
      state: "",
    },
    olivbranch: {
      order: 7,
      geo: {
        lat: 34.9618,
        lng: -89.8295,
      },
      header: "Olive Branch",
      id: "wfi-marker-6",
      link: "/turnkey#olivebranch",
      state: "",
    },
  },
  resources: {
    order: 2,
    header: "Resources",
    testimonials: {
      order: 1,
      header: "Testimonials",
      link: "/testimonials",
    },
    faq: {
      order: 2,
      header: "FAQs",
      link: "/faqs",
    },
    members: {
      order: 3,
      header: "Our Members",
      link: "/members",
    },
    contactus: {
      order: 4,
      header: "Contact Us",
      link: "/contact",
    },
    wfs: {
      order: 5,
      header: "White Feather Solar",
      link: "/wfs",
    },
    terms: {
      order: 6,
      header: "Site Disclaimer",
      link: "https://app.termly.io/document/disclaimer/62a92504-43a8-4535-9e4b-81c73fb50409",
      target: "_blank",
    },
  },
};
