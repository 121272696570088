import React, { Suspense, useEffect, useRef, useState } from "react";

//Vendor
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";

//Custom CSS
import links from "../../../links";

//Custom CSS
import "./navbar.css";

//Custom Image
import WFIImage from "../../../dist/img/brand/wfi-logo-text-white.png";

//Custom Components
const Sidebar = React.lazy(() => import("../sidebar/Sidebar"));
const MobileMenu = React.lazy(() => import("../MobileMenu"));

function GlobalNavbar({ mobile }) {
  const [transparent, setTransparent] = useState(true);
  const [show, setShow] = useState(false);
  const navRef = useRef();
  const location = useLocation();
  navRef.current = transparent;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY < 100;
      if (navRef.current !== show) {
        setTransparent(show);
      }
    };
    if (location.pathname.includes("mastermind")) {
      setTransparent(false);
    }
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const actions = {
    signin: () => (window.location.href = "https://app.tractic.io"),
  };

  const DropdownItems = ({ links }) => {
    return Object.keys(links).map((key, j) => {
      if (links[key].target) {
        return (
          <a
            href={links[key].link}
            key={`wfi-dropdown-item-${j}`}
            target={links[key].target}
          >
            <div className="navbar-dropdown-item">{links[key].header}</div>
          </a>
        );
      }
      return (
        <Link key={`wfi-dropdown-item-${j}`} to={links[key].link}>
          <div className="navbar-dropdown-item">{links[key].header}</div>
        </Link>
      );
    });
  };

  const MenuItems = ({ links }) => {
    return Object.keys(links).map((key, i) => {
      var linksonly = Object.keys(links[key])
        .map((key2) => links[key][key2])
        .filter((obj) => typeof obj === "object");

      return (
        <NavDropdown
          id={links[key].header}
          key={`wfi-dropdown-${i}`}
          title={links[key].header}
        >
          <DropdownItems links={linksonly} />
        </NavDropdown>
      );
    });
  };

  return (
    <>
      <Navbar
        id="wfi-navbar"
        expand="md"
        fixed="top"
        bg="none"
        className={transparent ? "transparent" : "wfi-background-color-1"}
      >
        <Navbar.Brand href="/">
          <img
            src={WFIImage}
            width="auto"
            height="50"
            className="d-inline-block align-top"
            alt="White Feather Investments"
          />
        </Navbar.Brand>
        <Navbar.Collapse className="ml-auto mr-5">
          <Nav className="ml-auto">
            <MenuItems links={links} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {mobile ? (
        <Suspense fallback={<React.Fragment></React.Fragment>}>
          <Sidebar setShow={setShow} show={show}>
            <Nav className="ml-auto">
              <MenuItems links={links} />
            </Nav>
          </Sidebar>
          <MobileMenu setShow={setShow} show={show} />
        </Suspense>
      ) : null}
    </>
  );
}
export default GlobalNavbar;
