import axios from "axios";

export default async function getMembers({
  count,
  lastEvaluatedKey,
  mobile,
  page,
  select
}) {
  return await axios.get("/members", {
    params: {
      count: count ? count : 10,
      lastEvaluatedKey,
      mobile: mobile || false,
      page: page ? page : "home",
      select
    }
  });
}
